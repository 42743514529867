import GoogleIcon from "@mui/icons-material/Google";
import { AuthButton } from "components/styled";
import React from "react";
import { GoogleLogin } from "react-google-login";

type GoogleAuthButtonProps = {
  onSuccess: (response: any) => void;
  onFailure: (error: any) => void;
  role?: string;
  isSignUp?: boolean;
};

const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  onSuccess,
  onFailure,
  role = "",
  isSignUp = false
}) => (
  <GoogleLogin
    clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
    render={(renderProps) => (
      <AuthButton
        variant="outlined"
        fullWidth
        onClick={() => {
          if (isSignUp && role === "") {
            // eslint-disable-next-line
            alert("Please select your role before proceeding with Google login");
          } else {
            renderProps.onClick();
          }
        }}
        disabled={renderProps.disabled}>
        <GoogleIcon fontSize="small" />
      </AuthButton>
    )}
    buttonText="Login"
    onSuccess={onSuccess}
    onFailure={onFailure}
    cookiePolicy="single_host_origin"
  />
);

export default GoogleAuthButton;
