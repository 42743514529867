import React from "react";
import styled from "styled-components";

import NavBar from "components/NavBar";
import Footer from "components/Footer";
import CareSquadHeader from "./CareSquadHeader";
import CareSquadCards from "./CareSquadCards";

const Content = styled.div`
  background-color: #fbf6f4;
  height: 80px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  width: 100%;
`;

const CareSquads = () => (
  <>
    <Content>
      <NavBar />
    </Content>
    <CareSquadHeader />
    <CareSquadCards />
    <Footer />
  </>
);

export default CareSquads;
