import { Link, Typography } from "@mui/material";
import { handleError } from "api/responseHandlers";
import Form from "components/Form";
import { FormikTextInput } from "components/Form/FormikFields";
import FormikSubmitButton from "components/Form/FormikSubmitButton";
import { Container, Line } from "components/styled";
import { useUserContext } from "components/UserContextProvider";
import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SESSION_ROUTES } from "routes/Session";
import styled from "styled-components";
import colors from "styles/colors";
import { v4 as uuidv4 } from "uuid";

import AppleAuthButton from "../AppleAuthButton";
import GoogleAuthButton from "../GoogleAuthButton";
import signInFields from "./fields";

const SignInForm = styled(Form)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const fieldsUI = (
  <>
    {signInFields.map((field) => (
      <FormikTextInput
        key={`${field.name} ${uuidv4()}`}
        type={field?.inputProps?.type || "text"}
        {...field}
      />
    ))}
  </>
);

const SignIn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [goolgeButtonVisible, setGoogleButtonVisible] = useState(true);
  const { oauthSignIn, signIn } = useUserContext();

  const handleGoogleAuth = async ({ profileObj }: any) => {
    oauthSignIn({ ...profileObj, from_sign_up: false });
  };

  const handleAppleAuth = async (response) => {
    const userPayload = {
      id_token: response?.authorization?.id_token,
      email: response?.user?.email || null,
      first_name: response?.user?.name?.firstName || null,
      last_name: response?.user?.name?.lastName || null,
      from_sign_up: false,
    };
    oauthSignIn({ ...userPayload });
  };

  // if third party cookies are not enabled then
  // google login won't work via JS
  // we should likely move this integration to the backend
  const handleGoogleError = (e: any) => {
    if (e.error === "idpiframe_initialization_failed") {
      setGoogleButtonVisible(false);
      return;
    }
    handleError(e);
  };

  const onSubmit = async ({ email, password }) => {
    setIsLoading(true);

    try {
      await signIn(email, password);

      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);

      const { error: errorMsg } = err?.response?.data || {};

      if (errorMsg?.includes("confirm your email")) {
        navigate(`/users${SESSION_ROUTES.CONFIRM_EMAIL_LANDING}`, {
          state: { email },
        });
      }
    }
  };

  return (
    <Container direction="column" gap="20px 0">
      <Container direction="column" justifyContent="space-between">
        <SignInForm fields={signInFields} onSubmit={onSubmit}>
          <Container direction="column" maxWidth="340px">
            <Container gap="28px">{fieldsUI}</Container>
            <Container
              justifyContent="flex-start"
              margin="8px 0 0 0"
              gap="20px"
            >
              <Link
                variant="link2"
                component={RouterLink}
                to={`/users${SESSION_ROUTES.FORGOT_PASSWORD}`}
              >
                Forgot your password?
              </Link>
              <FormikSubmitButton disableOnErrors disabled={isLoading}>
                <Typography variant="h5">Sign In</Typography>
              </FormikSubmitButton>
            </Container>
          </Container>
        </SignInForm>
      </Container>
      <Container maxWidth="340px" justifyContent="space-around">
        <Line />
        <Typography variant="body1" color={colors.GREY[7]}>
          Or
        </Typography>
        <Line />
      </Container>
      <Container justifyContent="space-around" maxWidth="340px">
        {goolgeButtonVisible && (
          <Container maxWidth="166px">
            <GoogleAuthButton
              onSuccess={handleGoogleAuth}
              onFailure={handleGoogleError}
            />
          </Container>
        )}

        <Container maxWidth="166px">
          <AppleAuthButton
            onSuccess={handleAppleAuth}
            onFailure={handleError}
          />
        </Container>
      </Container>
      <Container
        direction="column"
        justifyContent="space-around"
        maxWidth="340px"
        gap="4px"
      >
        <Typography variant="body2" style={{ color: colors.GREY[9] }}>
          Don&rsquo;t have an account?
        </Typography>

        <Container justifyContent="space-around">
          <Link
            variant="link2"
            component={RouterLink}
            to={`/users${SESSION_ROUTES.SIGN_UP}`}
          >
            Sign up here
          </Link>
        </Container>
      </Container>
    </Container>
  );
};

export default SignIn;
