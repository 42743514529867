import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import HIHHTooltip from "components/common/HIHHToolTip";
import { LogoutIcon } from "components/common/icons";
import { NoAvatarProfileContainer } from "components/styled";
import { useUserContext } from "components/UserContextProvider";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import styled from "styled-components";
import colors from "styles/colors";
import { v4 as uuidv4 } from "uuid";

const ProfilePicture = styled.img`
  border-radius: 50%;
  border: 2px solid ${colors.CORAL[1]};
  height: 50px;
  object-fit: cover;
  width: 50px;

  &:hover {
    border: 2px solid ${colors.CORAL[3]};
  }

  &.active {
    border: 2px solid ${colors.CORAL[5]};
  }
`;

const ProfileInitials = styled(Typography)`
  font-family: "Nunito Sans";
  letter-spacing: 0.1em;
`;

const AvatarMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { isAuthenticated, isOnboardingCompleted, user, logOut } =
    useUserContext();
  const navigate = useNavigate();
  const isProvider = user?.role === "provider";
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogOut = async () => logOut();

  const userInitials = useMemo(() => {
    if (!user) return "";

    let firstLetter = '';
    let lastLetter = '';

    if (user.first_name && user.last_name) {
      firstLetter = user.first_name.charAt(0);
      lastLetter = user.last_name.charAt(0);
    } else if (user.first_name && !user.last_name) {
      firstLetter = user.first_name.charAt(0);
    } else if (!user.first_name && user.last_name) {
      lastLetter = user.last_name.charAt(0);
    }

    return firstLetter + lastLetter;
  }, [user]);

  return (
    <>
      <HIHHTooltip arrow title="Account Settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          style={{ margin: "0", padding: "0", width: "50px", height: "50px" }}
        >
          {user?.profile_picture_url ? (
            <ProfilePicture
              src={user.profile_picture_url}
              className={open ? "active" : ""}
            />
          ) : (
            <NoAvatarProfileContainer
              style={{ backgroundColor: `${colors.CORAL[1]}` }}
            >
              { userInitials && (<ProfileInitials variant="h6">{userInitials}</ProfileInitials>) }
            </NoAvatarProfileContainer>
          )}
        </IconButton>
      </HIHHTooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "0px 0px 8px 8px",
            boxShadow: "0px 8px 16px -4px rgba(74, 79, 86, 0.05)",
            boxSizing: "border-box",
            minHeight: isOnboardingCompleted ? 167 : "auto",
            overflow: "hidden",
            mt: 1.5,
            width: 230,
            "& .MuiList-root": {
              padding: "0",
            },
            "& .MuiMenuItem-root": {
              padding: "16px",
              color: colors.NUDE[7],
              fontWeight: "bold",
              "& .Mui-focusVisible": {
                backgroundColor: "white",
              },
              "&:hover": {
                backgroundColor: colors.NUDE[1],
              },
              "&.logout": {
                color: colors.CORAL[5],
                "& path": {
                  stroke: colors.CORAL[5],
                },
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isProvider &&
          isOnboardingCompleted && [
            <MenuItem
              key={uuidv4()}
              onClick={() => navigate(`/provider/${user?.id}`)}
            >
              Published Profile
            </MenuItem>,
            <MenuItem
              key={uuidv4()}
              onClick={() => navigate(APP_ROUTES.accountSettings)}
            >
              My Profile
            </MenuItem>,
          ]}

        {!isProvider && isOnboardingCompleted && (
          <MenuItem
            key={uuidv4()}
            onClick={() => navigate(APP_ROUTES.accountSettings)}
          >
            My Profile
          </MenuItem>
        )}

        {isAuthenticated && isOnboardingCompleted && (
          <MenuItem
            key={uuidv4()}
            onClick={() => navigate(APP_ROUTES.favoriteProviders)}
          >
            Favorites
          </MenuItem>
        )}

        <Divider style={{ margin: 0 }} />

        <MenuItem className="logout" onClick={handleLogOut}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          Log Out
        </MenuItem>
      </Menu>
    </>
  );
};

export default AvatarMenu;
