import { Typography } from "@mui/material";
import { Container } from "components/styled";
import React from "react";
import styled from "styled-components";
import colors from "styles/colors";

const HomeLayout = styled(Container)`
  background-color: ${colors.CORAL[2]};
`;

const NotFound = () => (
  <HomeLayout height="100vh">
    <Typography variant="h1" color="white">
      404 - Not Found Page
    </Typography>
  </HomeLayout>
);

export default NotFound;
