import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import colors from "styles/colors";
import leftMobileHeaderBg from "assets/images/care-squads/left-mobile-header-bg.svg";
import rightMobileHeaderBg from "assets/images/care-squads/right-mobile-header-bg.svg";
import leftDesktopHeaderBg from "assets/images/care-squads/left-desktop-header-bg.svg";
import rightDesktopHeaderBg from "assets/images/care-squads/right-desktop-header-bg.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 640px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: #fbf6f4;
`;

const LeftHeaderBg = styled.div`
  background-image: url(${leftMobileHeaderBg});
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;

  position: absolute;
  width: 191.25px;
  height: 240px;
  left: -35px;
  top: -140px;

  @media all and (min-width: 1200px) {
    width: 510px;
    height: 640px;
    left: -65px;
    top: -158px;
    background-image: url(${leftDesktopHeaderBg});
  }
`;

const RightHeaderBg = styled.div`
  background-image: url(${rightMobileHeaderBg});
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;

  position: absolute;
  width: 304.7px;
  height: 293px;
  right: -10px;
  top: -153px;
  z-index: 10;

  @media all and (min-width: 1200px) {
    width: 656px;
    height: 677px;
    right: 0px;
    top: -72px;
    background-image: url(${rightDesktopHeaderBg});
  }
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 44px;
  margin-top: 25px;

  @media all and (min-width: 1200px) {
    height: 76px;
    margin-top: 96px;
  }
`;

const SubTitle = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 350px;
  height: 72px;
  margin: 16px auto;

  :before {
    content: " ";
    background: ${colors.BRONZE[6]};
    width: 24px;
    height: 1px;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
  }

  :after {
    content: " ";
    background: ${colors.BRONZE[6]};
    width: 24px;
    height: 1px;
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
  }

  span {
    display: block;
    width: 282px;
    height: 72px;
    color: ${colors.BRONZE[6]} !important;
    text-align: center;
    letter-spacing: 0.135em;
    text-transform: uppercase;
  }

  @media all and (min-width: 1200px) {
    width: 713px;
    height: 24px;

    span {
      width: 645px;
    }
  }
`;

const CareSquadHeader = () => (
  <Container>
    <LeftHeaderBg />
    <RightHeaderBg />
    <Title>
      <Typography variant="mk_serif_h3_2">Care Squads</Typography>
    </Title>
    <SubTitle>
      <Typography variant="mk_subtitle2">
        Care is done better in community. Find the Care Squad you need.
      </Typography>
    </SubTitle>
  </Container>
);

export default CareSquadHeader;
