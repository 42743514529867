import React from "react";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import BenefitsSection from "./BenefitsSection";
import HeroSection from "./HeroSection";
import MetricsSection from "./MetricsSection";

const Partnership = () => (
  <>
    <NavBar />
    <HeroSection />
    <BenefitsSection />
    <MetricsSection />
    <Footer />
  </>
);

export default Partnership;
