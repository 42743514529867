import React from "react";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, Link, Typography } from "@mui/material";
import AppLogo from "assets/logos/app-bar-logo.svg";
import { Container } from "components/styled";
import { Link as RouterLink } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { CAREERS_URL, CONTENT_URL } from "routes/constant";
import { openUrlInNewTab } from "routes/Profile/Provider/utils";
import colors from "styles/colors";
import SmartLink from "components/common/SmartLink";
import twitterSrc from "assets/images/twitter-x.svg";
import {
  fireUserClickedCarePointEvent,
  fireUserClickedCommunityEvent,
} from "utils/analytics";
import { useUserContext } from "components/UserContextProvider";
import {
  BannerLogo,
  FooterContainer,
  FooterDivider,
  FooterTypography,
  FooterTypographyLink,
  IconStyle,
  MainFooterContainer,
  SecondLineFooterContainer,
} from "./Footer.styles";

const Footer = () => {
  const user = useUserContext();

  return (
    <FooterContainer>
      <MainFooterContainer justifyContent="space-between">
        <Box display="flex" flexDirection="column" className="logo-container">
          <BannerLogo src={AppLogo} alt="logo" />
          <Typography
            variant="mk_serif_h2"
            color={colors.NUDE[9]}
            lineHeight="60px"
            sx={{
              "@media all and (max-width: 556px)": {
                fontSize: "2.25rem",
                lineHeight: "44px",
              },
            }}
          >
            Your Guide for Health and Wellness
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          columnGap="32px"
          className="footer-navigation-container"
        >
          <Box
            display="flex"
            flexDirection="column"
            rowGap="24px"
            className="links-container"
          >
            <Typography
              variant="body3"
              color={colors.NUDE[6]}
              fontWeight="800"
              textTransform="uppercase"
            >
              Community
            </Typography>
            <SmartLink
              link={APP_ROUTES.virtualCareSquads}
              style={{ textDecoration: "none" }}
            >
              <FooterTypographyLink
                variant="mk_body1"
                color={colors.NUDE[7]}
                fontWeight="700"
                lineHeight="normal"
                width="max-content"
                display="block"
              >
                Care Squads
              </FooterTypographyLink>
            </SmartLink>
            <SmartLink
              link={APP_ROUTES.community}
              style={{ textDecoration: "none" }}
            >
              <FooterTypographyLink
                variant="mk_body1"
                color={colors.NUDE[7]}
                fontWeight="700"
                lineHeight="normal"
                onClick={() => fireUserClickedCommunityEvent(user)}
              >
                Forum
              </FooterTypographyLink>
            </SmartLink>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            rowGap="24px"
            className="links-container"
          >
            <Typography
              variant="body3"
              color={colors.NUDE[6]}
              fontWeight="800"
              textTransform="uppercase"
            >
              Content
            </Typography>
            <SmartLink
              link={APP_ROUTES.carePoint}
              style={{ textDecoration: "none" }}
            >
              <FooterTypographyLink
                variant="mk_body1"
                color={colors.NUDE[7]}
                fontWeight="700"
                lineHeight="normal"
                onClick={() => fireUserClickedCarePointEvent(user)}
              >
                Carepoint
              </FooterTypographyLink>
            </SmartLink>
            <SmartLink link={CONTENT_URL} style={{ textDecoration: "none" }}>
              <FooterTypographyLink
                variant="mk_body1"
                color={colors.NUDE[7]}
                fontWeight="700"
                lineHeight="normal"
              >
                Library
              </FooterTypographyLink>
            </SmartLink>
            <SmartLink
              link={`${CONTENT_URL}/events`}
              style={{ textDecoration: "none" }}
            >
              <FooterTypographyLink
                variant="mk_body1"
                color={colors.NUDE[7]}
                fontWeight="700"
                lineHeight="normal"
              >
                Events
              </FooterTypographyLink>
            </SmartLink>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            rowGap="24px"
            className="links-container"
          >
            <Typography
              variant="body3"
              color={colors.NUDE[6]}
              fontWeight="800"
              textTransform="uppercase"
            >
              Connection
            </Typography>
            <SmartLink
              link={APP_ROUTES.directory}
              style={{ textDecoration: "none" }}
            >
              <FooterTypographyLink
                variant="mk_body1"
                color={colors.NUDE[7]}
                fontWeight="700"
                lineHeight="normal"
              >
                Provider
                <br />
                Directory
              </FooterTypographyLink>
            </SmartLink>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            rowGap="24px"
            className="links-container"
            width="fit-content !important"
          >
            <Typography
              variant="body3"
              color={colors.NUDE[6]}
              fontWeight="800"
              textTransform="uppercase"
            >
              Company
            </Typography>
            <SmartLink
              link={APP_ROUTES.aboutUs}
              style={{ textDecoration: "none" }}
            >
              <FooterTypographyLink
                variant="mk_body1"
                color={colors.NUDE[7]}
                fontWeight="700"
                lineHeight="normal"
              >
                About Us
              </FooterTypographyLink>
            </SmartLink>
            <SmartLink
              link={APP_ROUTES.partnership}
              style={{ textDecoration: "none" }}
            >
              <FooterTypographyLink
                variant="mk_body1"
                color={colors.NUDE[7]}
                fontWeight="700"
                lineHeight="normal"
              >
                Partners
              </FooterTypographyLink>
            </SmartLink>
            <SmartLink link={CAREERS_URL} style={{ textDecoration: "none" }}>
              <FooterTypographyLink
                variant="mk_body1"
                color={colors.NUDE[7]}
                fontWeight="700"
                lineHeight="normal"
              >
                Careers
              </FooterTypographyLink>
            </SmartLink>
            <SmartLink
              link={APP_ROUTES.contactUs}
              style={{ textDecoration: "none" }}
            >
              <FooterTypographyLink
                variant="mk_body1"
                color={colors.NUDE[7]}
                fontWeight="700"
                lineHeight="normal"
              >
                Contact
              </FooterTypographyLink>
            </SmartLink>
          </Box>
        </Box>
      </MainFooterContainer>
      <FooterDivider className="footer-divider" />
      <SecondLineFooterContainer>
        <Container style={{ width: "auto" }}>
          <FacebookRoundedIcon
            style={IconStyle}
            onClick={() =>
              openUrlInNewTab("https://www.facebook.com/HealthInHerHUE")
            }
          />
          <InstagramIcon
            style={IconStyle}
            onClick={() =>
              openUrlInNewTab("https://www.instagram.com/healthinherhue/?hl=en")
            }
          />
          <LinkedInIcon
            style={IconStyle}
            onClick={() =>
              openUrlInNewTab(
                "https://www.linkedin.com/company/18941044/admin/"
              )
            }
          />
          <SmartLink
            link="https://twitter.com/HealthInHerHUE"
            style={{ textDecoration: "none", height: "24px" }}
          >
            <img src={twitterSrc} alt="Twitter icon" width="24" height="24" style={IconStyle} />
          </SmartLink>
        </Container>
        <Box
          display="flex"
          flexDirection="row"
          columnGap="24px"
          sx={{
            "@media all and (max-width: 664px)": {
              flexDirection: "column",
              rowGap: "16px",
            },
          }}
        >
          <FooterTypography
            variant="link2"
            color={colors.NUDE[7]}
            className="link-typography"
          >
            <Link
              variant="link2"
              to={APP_ROUTES.healthEquityPledge}
              target="_self"
              component={RouterLink}
              color={colors.NUDE[7]}
              style={{ textDecoration: "underline" }}
            >
              Health Equity Pledge
            </Link>
          </FooterTypography>
          <FooterTypography
            variant="link2"
            color={colors.NUDE[7]}
            className="link-typography"
          >
            <Link
              variant="link2"
              to={APP_ROUTES.membershipIndividuals}
              target="_self"
              component={RouterLink}
              color={colors.NUDE[7]}
              style={{ textDecoration: "underline" }}
            >
              Frequently Asked Questions
            </Link>
          </FooterTypography>
          <FooterTypography
            variant="link2"
            color={colors.NUDE[7]}
            className="link-typography"
          >
            <Link
              variant="link2"
              to={APP_ROUTES.terms}
              target="_blank"
              component={RouterLink}
              color={colors.NUDE[7]}
              style={{ textDecoration: "underline" }}
            >
              Terms & Conditions
            </Link>
          </FooterTypography>
          <Typography variant="link2" color={colors.NUDE[7]}>
            <Link
              variant="link2"
              to={APP_ROUTES.privacy}
              target="_blank"
              component={RouterLink}
              color={colors.NUDE[7]}
              style={{ textDecoration: "underline" }}
            >
              Privacy Policy
            </Link>
          </Typography>
        </Box>
        <FooterTypography
          variant="link2"
          color={colors.NUDE[7]}
          style={{ fontWeight: "normal" }}
          className="text-typography"
        >
          © 2024 Health in Her HUE™
        </FooterTypography>
      </SecondLineFooterContainer>
    </FooterContainer>
  );
};
export default Footer;
