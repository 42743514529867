import { createTheme } from "@mui/material";
import React from "react";

import colors from "./colors";

declare module "@mui/material/styles/createTypography" {
  interface Typography {
    h7: React.CSSProperties;
    h8: React.CSSProperties;
    body3: React.CSSProperties;
    link1: React.CSSProperties;
    link2: React.CSSProperties;
    link3: React.CSSProperties;
    link4: React.CSSProperties;
    link5: React.CSSProperties;
    mk_h1: React.CSSProperties;
    mk_h2: React.CSSProperties;
    mk_h3: React.CSSProperties;
    mk_h4: React.CSSProperties;
    mk_h5: React.CSSProperties;
    mk_h6: React.CSSProperties;
    mk_serif_h1: React.CSSProperties;
    mk_serif_h2: React.CSSProperties;
    mk_serif_h3: React.CSSProperties;
    mk_serif_h3_2: React.CSSProperties;
    mk_serif_h4: React.CSSProperties;
    mk_subtitle: React.CSSProperties;
    mk_subtitle2: React.CSSProperties;
    mk_subtitle3: React.CSSProperties;
    mk_subtitle4: React.CSSProperties;
    mk_body1: React.CSSProperties;
    mk_body2: React.CSSProperties;
    mk_caption: React.CSSProperties;
    mk_overline: React.CSSProperties;
  }

  interface TypographyOptions {
    h7: React.CSSProperties;
    h8: React.CSSProperties;
    body3: React.CSSProperties;
    link1: React.CSSProperties;
    link2: React.CSSProperties;
    link3: React.CSSProperties;
    link4: React.CSSProperties;
    link5: React.CSSProperties;
    mk_h1?: React.CSSProperties;
    mk_h2?: React.CSSProperties;
    mk_h3?: React.CSSProperties;
    mk_h4?: React.CSSProperties;
    mk_h5?: React.CSSProperties;
    mk_h6?: React.CSSProperties;
    mk_serif_h1?: React.CSSProperties;
    mk_serif_h2?: React.CSSProperties;
    mk_serif_h3?: React.CSSProperties;
    mk_serif_h3_2?: React.CSSProperties;
    mk_serif_h4?: React.CSSProperties;
    mk_subtitle?: React.CSSProperties;
    mk_subtitle2?: React.CSSProperties;
    mk_subtitle3?: React.CSSProperties;
    mk_subtitle4?: React.CSSProperties;
    mk_body1?: React.CSSProperties;
    mk_body2?: React.CSSProperties;
    mk_caption?: React.CSSProperties;
    mk_overline?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    link1: true;
    link2: true;
    link3: true;
    link4: true;
    link5: true;
    mk_h1: true;
    mk_h2: true;
    mk_h3: true;
    mk_h4: true;
    mk_h5: true;
    mk_h6: true;
    mk_serif_h1: true;
    mk_serif_h2: true;
    mk_serif_h3: true;
    mk_serif_h3_2: true;
    mk_serif_h4: true;
    mk_subtitle: true;
    mk_subtitle2: true;
    mk_subtitle3: true;
    mk_subtitle4: true;
    mk_body1: true;
    mk_body2: true;
    mk_caption: true;
    mk_overline: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    icon: true;
    secondaryOutlined: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: colors.CORAL[5],
    },
    secondary: {
      main: colors.CORAL[4],
    },
    error: {
      main: colors.RED[5],
    },
    success: {
      main: colors.NUDE[5],
    },
  },
  typography: {
    fontFamily: "Nunito Sans, sans-serif",
    h1: {
      fontFamily: "Noto Serif, serif",
      fontSize: "3.75rem",
      fontWeight: 400,
      lineHeight: "78px",
    },
    h2: {
      fontFamily: "Noto Serif, serif",
      fontSize: "3rem",
      fontWeight: 400,
      lineHeight: "60px",
    },
    h3: {
      fontFamily: "Noto Serif, serif",
      fontSize: "2.25rem",
      fontWeight: 400,
      lineHeight: "44px",
    },
    h4: {
      fontFamily: "Noto Serif, serif",
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: "32px",
    },
    h5: {
      fontFamily: "Nunito Sans",
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "28px",
    },
    h6: {
      fontFamily: "Nunito Sans",
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: "24px",
    },
    h7: {
      fontFamily: "Nunito Sans",
      fontSize: "0.75rem",
      fontWeight: 700,
      lineHeight: "20px",
      textTransform: "uppercase",
    },
    h8: {
      fontFamily: "Noto Serif, serif",
      fontSize: "1.75rem",
      fontWeight: 700,
      lineHeight: "40px",
    },
    subtitle1: {
      fontFamily: "Nunito Sans, sans-serif",
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: "20px",
    },
    subtitle2: {
      fontFamily: "Nunito Sans, sans-serif",
      fontSize: "0.75rem",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "uppercase",
    },
    body1: {
      fontFamily: "Nunito Sans, sans-serif",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "24px",
    },
    body2: {
      fontFamily: "Nunito Sans, sans-serif",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "20px",
    },
    body3: {
      fontFamily: "Nunito Sans, sans-serif",
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "16px",
    },
    link1: {
      fontFamily: "Nunito Sans, sans-serif",
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: "20px",
    },
    link2: {
      fontFamily: "Nunito Sans, sans-serif",
      fontSize: "0.875rem",
      fontWeight: 700,
      lineHeight: "13px",
    },
    link3: {
      fontFamily: "Nunito Sans, sans-serif",
      fontSize: "0.75rem",
      fontWeight: 700,
      lineHeight: "16px",
    },
    link4: {
      fontFamily: "Nunito Sans, sans-serif",
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "28px",
    },
    link5: {
      fontFamily: "Nunito Sans",
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: "24px",
    },
    mk_h1: {
      fontFamily: "Nunito Sans, serif",
      fontSize: "3.75rem",
      fontWeight: 700,
      lineHeight: "76px",
    },
    mk_h2: {
      fontFamily: "Nunito Sans, serif",
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: "72px",
    },
    mk_h3: {
      fontFamily: "Nunito Sans, serif",
      fontSize: "2rem",
      fontWeight: 700,
      lineHeight: "48px",
    },
    mk_h4: {
      fontFamily: "Nunito Sans, serif",
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "32px",
    },
    mk_h5: {
      fontFamily: "Nunito Sans, serif",
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "28px",
    },
    mk_h6: {
      fontFamily: "Nunito Sans, serif",
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: "24px",
    },
    mk_serif_h1: {
      fontFamily: "Noto Serif, serif",
      fontSize: "3.75rem",
      fontWeight: 500,
      lineHeight: "76px",
    },
    mk_serif_h2: {
      fontFamily: "Noto Serif, serif",
      fontSize: "3rem",
      fontWeight: 500,
      lineHeight: "72px",
    },
    mk_serif_h3: {
      fontFamily: "Noto Serif, serif",
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: "48px",
    },
    mk_serif_h3_2: {
      fontFamily: "Noto Serif, serif",
      fontSize: "2.25rem",
      fontWeight: 500,
      lineHeight: "44px",
    },
    mk_serif_h4: {
      fontFamily: "Noto Serif, serif",
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: "40px",
    },
    mk_subtitle: {
      fontFamily: "Nunito Sans, serif",
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: "32px",
    },
    mk_subtitle2: {
      fontFamily: "Nunito Sans, serif",
      fontSize: "0.875rem",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#B58274",
      textTransform: "uppercase",
    },
    mk_subtitle3: {
      fontFamily: "Nunito Sans",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "20px",
      color: "#FFFFFF",
      textTransform: "uppercase",
    },
    mk_subtitle4: {
      fontFamily: "Nunito Sans",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "13.5%",
    },
    mk_body1: {
      fontFamily: "Nunito Sans, serif",
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: "32px",
    },
    mk_body2: {
      fontFamily: "Nunito Sans, serif",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "24px",
    },
    mk_caption: {
      fontFamily: "Nunito Sans, serif",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "22px",
    },
    mk_overline: {
      fontFamily: "Nunito Sans, serif",
      fontSize: "0.75rem",
      fontWeight: 800,
      lineHeight: "16px",
      color: "#D56B57",
      textTransform: "uppercase",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          "&:focus, &:active": {
            color: colors.CORAL[6],
            textDecoration: "underline",
          },
          "&.disabled": {
            pointerEvents: "none",
            color: colors.GREY[2],
          },
        },
      },
      defaultProps: {
        underline: "hover",
      },
      variants: [
        {
          props: { variant: "link1" },
          style: {
            fontFamily: "Nunito Sans, sans-serif",
            fontSize: "1rem",
            fontWeight: 700,
            lineHeight: "20px",
          },
        },
        {
          props: { variant: "link2" },
          style: {
            fontFamily: "Nunito Sans, sans-serif",
            fontSize: "0.875rem",
            fontWeight: 700,
            lineHeight: "20px",
          },
        },
        {
          props: { variant: "link3" },
          style: {
            fontFamily: "Nunito Sans, sans-serif",
            fontSize: "0.75rem",
            fontWeight: 700,
            lineHeight: "16px",
          },
        },
        {
          props: { variant: "link4" },
          style: {
            fontFamily: "Nunito Sans, sans-serif",
            fontSize: "1.25rem",
            fontWeight: 700,
            lineHeight: "28px",
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          fontSize: "16px",
          padding: "14px 28px",
          height: 48,
          transition: "all ease-in-out .3s",
          lineHeight: "20px",
          borderRadius: "44px",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: colors.WHITE,
            transition: "all ease-in-out .3s",
            "&.Mui-disabled": {
              backgroundColor: colors.CORAL[1],
              color: colors.WHITE,
            },
            "&:hover": {
              backgroundColor: colors.CORAL[4],
              boxShadow: "0px 4px 4px -1px rgba(145, 83, 85, 0.08)",
              transition: "all ease-in-out .3s",
            },

            "&:active": {
              backgroundColor: colors.CORAL[6],
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            color: colors.CORAL[5],
            "&.Mui-disabled": {
              color: colors.CORAL[2],
            },
            "&:hover": {
              backgroundColor: colors.CORAL[5],
              color: colors.WHITE,
              boxShadow: "0px 4px 4px -1px rgba(145, 83, 85, 0.08)",
              transition: "all ease-in-out .3s",
            },
            "&:focus": {
              border: `3px solid ${colors.CORAL[1]}`,
            },
            "&:active": {
              backgroundColor: colors.CORAL[6],
              color: colors.CORAL[1],
            },
            "&:active:focus": {
              border: "none",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            backgroundColor: colors.WHITE,
            border: `1px solid ${colors.CORAL[5]}`,
            color: colors.CORAL[5],
            "&.Mui-disabled": {
              border: "none",
              color: colors.CORAL[2],
            },
            "&:hover": {
              backgroundColor: colors.CORAL[4],
              border: `1px solid ${colors.CORAL[4]}`,
              color: colors.CORAL[1],
              transition: "all ease-in-out .3s",
            },
            "&:hover svg path": {
              fill: colors.CORAL[1],
            },
            "&:active": {
              backgroundColor: colors.WHITE,
              border: `3px solid ${colors.CORAL[1]}`,
            },
            "&.selected svg path": {
              fill: colors.WHITE,
            },
            "&.selected": {
              backgroundColor: colors.CORAL[6],
              color: colors.WHITE,
              border: "none",
            },
          },
        },
        {
          props: { variant: "icon" },
          style: {
            backgroundColor: colors.WHITE,
            padding: "12px",
            borderRadius: "24px",
            width: "48px",
            height: "48px",
            color: colors.CORAL[5],
            "&.Mui-disabled": {
              color: colors.CORAL[2],
            },
            "&:hover": {
              backgroundColor: colors.CORAL[4],
              color: colors.CORAL[1],
              transition: "all ease-in-out .3s",
            },
            "&:hover svg path": {
              stroke: colors.WHITE,
            },
            "&:active": {
              backgroundColor: colors.WHITE,
            },
            "&.selected svg path": {
              fill: colors.WHITE,
            },
            "&.selected": {
              backgroundColor: colors.CORAL[6],
              color: colors.WHITE,
            },
          },
        },
        {
          props: { variant: "secondaryOutlined" },
          style: {
            backgroundColor: colors.WHITE,
            border: `1px solid ${colors.GREY[2]}`,
            color: colors.GREY[6],
            fontSize: "14px",
            "&.Mui-disabled": {
              color: colors.GREY[2],
            },
            "&.Mui-disabled svg path": {
              stroke: colors.GREY[2],
            },
            "&:hover": {
              backgroundColor: colors.WHITE,
              border: `1px solid ${colors.NUDE[5]}`,
              color: colors.GREY[5],
              transition: "all ease-in-out .3s",
            },
            "&:hover svg path": {
              stroke: colors.NUDE[3],
            },
            "&:active": {
              backgroundColor: colors.NUDE[1],
              border: `1px solid ${colors.NUDE[5]}`,
              color: colors.NUDE[8],
            },
            "&:focus": {
              backgroundColor: colors.NUDE[1],
              border: `1px solid ${colors.NUDE[5]}`,
              color: colors.NUDE[8],
            },
          },
        },
      ],
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: "12px",
          fontWeight: 700,
          lineHeight: "16px",
          color: colors.GREY[4],
          marginTop: "12px",
          "&.MuiStepLabel-alternativeLabel": {
            marginTop: "12px",
            fontWeight: 700,
            color: colors.GREY[4],
          },
          "&.Mui-completed": {
            color: colors.GREY[2],
          },
          "&.Mui-active": {
            color: colors.NUDE[5],
          },
          "@media (max-width: 480px)": {
            display: "none",
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: colors.WHITE,
          border: `2px solid ${colors.GREY[1]}`,
          borderRadius: "50%",
          "&.Mui-active": {
            border: `2px solid ${colors.NUDE[1]}`,
            color: colors.NUDE[5],
            text: {
              fill: colors.WHITE,
            },
          },
          "&.Mui-completed": {
            color: colors.NUDE[5],
            border: `2px solid ${colors.NUDE[1]}`,
          },
        },
        text: {
          fill: colors.GREY[4],
          fontWeight: 700,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          left: "calc(-50% + 12px)",
          right: "calc(50% + 12px)",
          "&.Mui-completed  .MuiStepConnector-line": {
            borderColor: colors.NUDE[5],
          },
        },
        line: {
          borderTopWidth: "2px",
          borderColor: colors.GREY[1],
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: colors.GREY[1],
          height: 6,
        },
        bar: {
          borderRadius: 20,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          border: 0,
          borderRadius: 4,
          backgroundColor: colors.CORAL[1],
          color: colors.GREY[6],
          fontWeight: 700,
        },
        popper: {
          boxShadow: "0px 8px 6px -4px rgba(74, 79, 86, 0.06)",
          border: `1px solid ${colors.GREY[2]}`,
        },
        option: {
          color: colors.GREY[6],
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: "20px",
          padding: "12px",
          "&:hover": {
            backgroundColor: colors.GREY[1],
            color: colors.GREY[5],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: "49px",
          color: colors.GREY[7],
          "&.Mui-focused": {
            boxShadow: `0px 0px 0px 4px ${colors.NUDE[2]}`,
            borderRadius: 6,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.NUDE[5],
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.NUDE[5],
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.GREY[2],
          },
          "& .MuiOutlinedInput-notchedOutline": {
            padding: "0 16px",
          },
        },
        input: {
          padding: "14px 12px",
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: "20px",
        },
        notchedOutline: {
          borderColor: colors.GREY[4],
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.GREY[3],
          lineHeight: "24px",
          "&.Mui-focused": {
            backgroundColor: colors.WHITE,
            padding: "0 6px",
            color: colors.GREY[3],
            fontWeight: 700,
            transform: "translate(16px, -9px) scale(0.75)",
          },
          "&.Mui-disabled": {
            color: colors.GREY[2],
          },
          "&.Mui-error": {
            color: colors.GREY[3],
          },
          "&.Mui-error.Mui-focused": {
            fontWeight: 700,
          },
          "&[data-shrink=true]": {
            color: colors.GREY[3],
            fontWeight: 700,
            padding: "0 6px",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            marginLeft: 0,
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: "16px",
            color: colors.RED[5],
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: colors.GREY[6],
          borderRadius: "44px",
          border: `1px solid ${colors.GREY[1]}`,
          "&.Mui-selected": {
            backgroundColor: colors.NUDE[1],
            border: `1px solid ${colors.NUDE[5]}`,
            color: colors.NUDE[8],
          },
          "&.Mui-selected:hover": {
            boxShadow: "0px 4px 4px -1px rgba(145, 83, 85, 0.08)",
            border: `1px solid ${colors.NUDE[5]}`,
            backgroundColor: colors.NUDE[1],
            color: colors.GREY[5],
          },
          "& svg": {
            marginRight: "4px",
          },
          "&:hover": {
            boxShadow: "0px 4px 4px -1px rgba(145, 83, 85, 0.08)",
            border: `1px solid ${colors.NUDE[5]}`,
            backgroundColor: colors.WHITE,
            color: colors.GREY[5],
          },
          "&:focus": {
            backgroundColor: colors.NUDE[1],
          },
          "&:active": {
            backgroundColor: colors.NUDE[1],
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 8,
          height: 34,
          width: 52,
          "& .MuiSwitch-track": {
            borderRadius: 22 / 2,
          },
          "& .MuiSwitch-thumb": {
            boxShadow: "none",
            width: 16,
            height: 16,
            margin: 2,
          },
        },
        switchBase: {
          padding: "8px 10px",
          "&.Mui-checked": {
            transform: "translateX(14px)",
          },
          "&:hover.Mui-checked .MuiSwitch-thumb": {
            backgroundColor: colors.CORAL[4],
          },
          "&:hover .MuiSwitch-thumb": {
            backgroundColor: colors.GREY[4],
          },
          "&.Mui-checked .MuiSwitch-thumb": {
            backgroundColor: colors.CORAL[5],
          },
          "& .MuiSwitch-thumb": {
            backgroundColor: colors.GREY[5],
          },
          "&.Mui-checked+.MuiSwitch-track": {
            backgroundColor: colors.CORAL[1],
            border: `1px solid ${colors.CORAL[5]}`,
          },
          "&+.MuiSwitch-track": {
            backgroundColor: colors.GREY[1],
            border: `1px solid ${colors.GREY[1]}`,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: "-9px",
        },
        label: {
          fontSize: "0.875rem",
          fontWeight: 700,
          lineHeight: "20px",
          color: colors.GREY[6],
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: colors.GREY[1],
        },
        thumb: {
          width: "24px",
          height: "24px",
          color: colors.WHITE,
          border: `2px solid ${colors.GREY[1]}`,
          "&:before": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: "44px",
          "&:hover": {
            backgroundColor: colors.CORAL[4],
            color: colors.WHITE,
          },
          "&.Mui-selected": {
            backgroundColor: `${colors.CORAL[6]} !important`,
            color: colors.WHITE,
          },
        },
        textPrimary: {
          "&.Mui-selected": {
            color: colors.WHITE,
          },
        },
        icon: {
          color: colors.CORAL[5],
        },
      },
    },
  },
});

export default theme;
