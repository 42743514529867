import React from "react";
import { Button, Divider, IconButton, Link, Typography } from "@mui/material";
import HIHHLogo from "assets/logos/app-bar-logo.svg";
import { CrossIcon, DrawerMenuIcon, LogoutIcon } from "components/common/icons";
import DrawerMenu from "components/DrawerMenu";
import { useMenuContext } from "components/MenuContextProvider";
import { Container } from "components/styled";
import { useUserContext } from "components/UserContextProvider";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { CONTENT_URL } from "routes/constant";
import { SESSION_ROUTES } from "routes/Session";
import colors from "styles/colors";
import {
  fireUserClickedCarePointEvent,
  fireUserClickedCommunityEvent,
  fireUserClickedLoginButtonEvent,
  fireUserClickedSignUpButtonEvent,
  fireUserViewedPageEvent,
} from "utils/analytics";
import {
  ContentContainer,
  DrawerContainer,
  MenuItemButton,
  NavLogo,
} from "./MobileNavMenu.styles";

const MobileNavMenu = () => {
  const { isNavMenuOpen, setIsNavMenuOpen } = useMenuContext();
  const {
    logOut,
    isAuthenticated: isLoggedIn,
    isOnboardingCompleted,
    user,
  } = useUserContext();
  const navigate = useNavigate();
  const isProvider = user?.role === "provider";
  const handleLogOut = async () => {
    setIsNavMenuOpen(false);
    logOut();
  };

  const { pathname } = useLocation();

  const handleLoginClick = () => {
    const to = `/users${SESSION_ROUTES.SIGN_IN}`;
    fireUserClickedLoginButtonEvent(pathname === APP_ROUTES.carePoint);
    setIsNavMenuOpen(false);
    navigate(to, { replace: true, state: { from: pathname } });
  };

  return (
    <DrawerContainer width="auto" gap="32px">
      <DrawerMenu
        id="mobile-nav-menu"
        icon={<DrawerMenuIcon />}
        anchor="right"
        open={isNavMenuOpen}
        setOpen={setIsNavMenuOpen}
        width="90%"
      >
        <ContentContainer width="auto" alignItems="start">
          <Container
            justifyContent="space-between"
            alignSelf="flex-start"
            margin="0 0 32px 0"
          >
            <NavLogo
              src={HIHHLogo}
              alt="app-bar-logo"
              height="16px"
              onClick={() => {
                navigate("/");
                setIsNavMenuOpen(false);
              }}
            />
            <IconButton
              onClick={() => setIsNavMenuOpen(false)}
              style={{ padding: 0 }}
            >
              <CrossIcon color={colors.CORAL[5]} />
            </IconButton>
          </Container>
          <Container
            alignSelf="flex-start"
            direction="column"
            alignItems="start"
            width="100%"
          >
            <Typography
              paddingBottom="24px"
              variant="mk_overline"
              color={colors.GREY[4]}
            >
              Community
            </Typography>
            <MenuItemButton
              onClick={() => {
                setIsNavMenuOpen(false);
                navigate("/virtual-care-squads");
              }}
              className="menu-item-btn"
            >
              <Typography variant="h6" color={colors.GREY[6]}>
                Care Squads
              </Typography>
            </MenuItemButton>
            <MenuItemButton
              onClick={() => {
                setIsNavMenuOpen(false);
                fireUserClickedCommunityEvent(user);
                navigate("/community");
              }}
              className="menu-item-btn"
            >
              <Typography variant="h6" color={colors.GREY[6]}>
                Forum
              </Typography>
            </MenuItemButton>
            <Divider flexItem style={{ marginBottom: 24 }} />
            <Typography
              paddingBottom="24px"
              variant="mk_overline"
              color={colors.GREY[4]}
            >
              Content
            </Typography>
            <MenuItemButton
              onClick={() => {
                setIsNavMenuOpen(false);
                fireUserClickedCarePointEvent(user);
                navigate("/carepoint");
              }}
              className="menu-item-btn"
            >
              <Typography variant="h6" color={colors.GREY[6]}>
                Carepoint
              </Typography>
            </MenuItemButton>
            <MenuItemButton
              onClick={() => {
                fireUserViewedPageEvent(user, CONTENT_URL);
                window.open(CONTENT_URL, "_self");
              }}
              className="menu-item-btn"
            >
              <Typography variant="h6" color={colors.GREY[6]}>
                Library
              </Typography>
            </MenuItemButton>
            <MenuItemButton
              onClick={() => {
                setIsNavMenuOpen(false);
                window.open(`${CONTENT_URL}/events`, "_self");
              }}
              className="menu-item-btn"
            >
              <Typography variant="h6" color={colors.GREY[6]}>
                Events
              </Typography>
            </MenuItemButton>
            <Divider flexItem style={{ marginBottom: 24 }} />
            <Typography
              paddingBottom="24px"
              variant="mk_overline"
              color={colors.GREY[4]}
            >
              Connection
            </Typography>
            <MenuItemButton
              onClick={() => {
                setIsNavMenuOpen(false);
                navigate("/directory/providers");
              }}
              className="menu-item-btn"
            >
              <Typography variant="h6" color={colors.GREY[6]}>
                Provider Directory
              </Typography>
            </MenuItemButton>
            <Divider flexItem style={{ marginBottom: 24 }} />
            <MenuItemButton
              onClick={() => {
                setIsNavMenuOpen(false);
                navigate(APP_ROUTES.partnership);
              }}
              className="menu-item-btn"
            >
              <Typography variant="h6" color={colors.GREY[6]}>
                Partners
              </Typography>
            </MenuItemButton>
            {!isLoggedIn && (
              <Container direction="column" gap="14px">
                <Divider flexItem />
                <Typography
                  padding="24px 0"
                  variant="mk_overline"
                  color={colors.GREY[4]}
                  sx={{ alignSelf: "flex-start" }}
                >
                  My Account
                </Typography>

                <Button
                  variant="outlined"
                  style={{
                    width: "100%",
                  }}
                  onClick={handleLoginClick}
                >
                  Log In
                </Button>
                <Link
                  style={{ textDecoration: "none", width: "100%" }}
                  variant="link2"
                  state={{ from: pathname }}
                  component={RouterLink}
                  to={`/users${SESSION_ROUTES.SIGN_UP}`}
                >
                  <Button
                    onClick={() =>
                      fireUserClickedSignUpButtonEvent(
                        pathname === APP_ROUTES.carePoint
                      )
                    }
                    variant="contained"
                    style={{ width: "100%" }}
                  >
                    Join
                  </Button>
                </Link>
              </Container>
            )}
            {isLoggedIn && user && (
              <>
                <Divider flexItem />
                <Typography
                  padding="24px 0"
                  variant="mk_overline"
                  color={colors.GREY[4]}
                  sx={{ alignSelf: "flex-start" }}
                >
                  My Account
                </Typography>
                {isProvider && isOnboardingCompleted && (
                  <>
                    <MenuItemButton
                      onClick={() => {
                        setIsNavMenuOpen(false);
                        navigate(`/provider/${user?.id}`);
                      }}
                      className="menu-item-btn"
                    >
                      <Typography variant="h6" color={colors.GREY[6]}>
                        Published Profile
                      </Typography>
                    </MenuItemButton>
                    <MenuItemButton
                      onClick={() => {
                        setIsNavMenuOpen(false);
                        navigate(APP_ROUTES.accountSettings);
                      }}
                      className="menu-item-btn"
                    >
                      <Typography variant="h6" color={colors.GREY[6]}>
                        My Profile
                      </Typography>
                    </MenuItemButton>
                  </>
                )}
                {!isProvider && isOnboardingCompleted && (
                  <MenuItemButton
                    onClick={() => {
                      setIsNavMenuOpen(false);
                      navigate(APP_ROUTES.accountSettings);
                    }}
                    className="menu-item-btn"
                  >
                    <Typography variant="h6" color={colors.GREY[6]}>
                      My Profile
                    </Typography>
                  </MenuItemButton>
                )}
                {isOnboardingCompleted && (
                  <MenuItemButton
                    onClick={() => {
                      setIsNavMenuOpen(false);
                      navigate(APP_ROUTES.favoriteProviders);
                    }}
                    className="menu-item-btn"
                  >
                    <Typography variant="h6" color={colors.GREY[6]}>
                      Favorites
                    </Typography>
                  </MenuItemButton>
                )}
                <MenuItemButton
                  onClick={handleLogOut}
                  className="menu-item-btn"
                >
                  <LogoutIcon />
                  <Typography
                    variant="h6"
                    color={colors.CORAL[5]}
                    style={{
                      marginLeft: "8px",
                    }}
                  >
                    Log Out
                  </Typography>
                </MenuItemButton>
              </>
            )}
          </Container>
        </ContentContainer>
      </DrawerMenu>
    </DrawerContainer>
  );
};

export default MobileNavMenu;
