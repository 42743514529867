import { Link, Menu, MenuItem as MUIMenuItem, Typography } from "@mui/material";
import { useUserContext } from "components/UserContextProvider";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import colors from "styles/colors";
import {
  fireUserClickedCommunityEvent,
  fireUserClickedCarePointEvent,
} from "utils/analytics";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { CONTENT_URL } from "routes/constant";
import { APP_ROUTES } from "routes";
import { NavbarButton } from "./NavBar.styles";

const MenuLink = styled<any>(Link)`
  width: 100%;
  &.menu-item-link {
    color: ${colors.NUDE[7]};
    :hover,
    :focus,
    :active {
      color: ${colors.NUDE[7]};
      text-decoration: none;
      background-color: transparent;
      box-shadow: none;
    }
  }
`;

const ExternalMenuLink = styled(MenuLink).attrs({ as: "a" })`
  text-decoration: none;
`;

type MenuItem = {
  route: string;
  label: string;
};

type MenuBaseProps = {
  label: string;
  items: MenuItem[];
};

type MenuItemBaseProps = {
  item: MenuItem;
};

const MenuItemBase = ({ item }: MenuItemBaseProps) => {
  const { user } = useUserContext();

  const onClick = (e) => {
    e.preventDefault();

    if (item.route === APP_ROUTES.community) {
      fireUserClickedCommunityEvent(user);
    }

    if (item.route === APP_ROUTES.carePoint) {
      fireUserClickedCarePointEvent(user);
    }

    if (item.route.includes(CONTENT_URL)) {
      window.open(item.route, "_self");
    }
  };

  return (
    <MUIMenuItem onClick={onClick}>
      {item.route.includes(CONTENT_URL) ? (
        <ExternalMenuLink className="menu-item-link" href={item.route}>
          {item.label}
        </ExternalMenuLink>
      ) : (
        <MenuLink
          className="menu-item-link"
          to={item.route}
          component={RouterLink}
        >
          {item.label}
        </MenuLink>
      )}
    </MUIMenuItem>
  );
};

const MenuBase = ({ label, items }: MenuBaseProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <NavbarButton onClick={handleClick} className="navbar-item-btn">
        <Typography
          variant="link1"
          fontSize={18}
          fontWeight={400}
          color={colors.NUDE[9]}
        >
          {label}
        </Typography>
        {open ? <ExpandLess color="success" /> : <ExpandMore color="success" />}
      </NavbarButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "0px 0px 8px 8px",
            boxShadow: "0px 8px 16px -4px rgba(74, 79, 86, 0.05)",
            boxSizing: "border-box",
            minHeight: "auto",
            overflow: "hidden",
            width: 208,
            "& .MuiList-root": {
              padding: "0",
            },
            "& .MuiMenuItem-root": {
              padding: "18px 24px",
              color: colors.GREY[6],
              fontWeight: "bold",
              "& .Mui-focusVisible": {
                backgroundColor: "white",
              },
              "&:hover": {
                backgroundColor: colors.NUDE[1],
              },
            },
          },
        }}
        anchorPosition={{ left: 0, top: 0 }}
      >
        {items.map((item) => (
          <MenuItemBase key={item.route} item={item} />
        ))}
      </Menu>
    </>
  );
};

export default MenuBase;
