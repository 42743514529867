import { Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import * as S from "./styles";
import heroImg from "../assets/hero.svg";
import BookADemo from "../BookADemo";

const HeroSection = () => {
  const [showModal, setShowModal] = useState(false);
  const handleBookADemo = useCallback(() => setShowModal((s) => !s), []);

  return (
    <>
      <S.StyledHeroContainer>
        <S.StyledHeroColumn>
          <S.TitleTypography variant="mk_h1" fontWeight={500}>
            Partner with Us
          </S.TitleTypography>
          <Typography fontSize={28} fontWeight={700}>
            What We Do
          </Typography>
          <Typography variant="mk_subtitle">
            Health in Her HUE brings culturally tailored care, culturally
            relevant health content, and community support for Black women,
            women of color, and gender-expansive people of color. Learn how
            Health in her HUE can help your organization build trust and connect
            with underrepresented voices.
          </Typography>
          <S.StyledButton onClick={handleBookADemo} variant="contained">
            Schedule a Meeting
          </S.StyledButton>
        </S.StyledHeroColumn>
        <S.StyledHeroImg src={heroImg} />
      </S.StyledHeroContainer>
      <BookADemo isOpen={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};

export default HeroSection;
