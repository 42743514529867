export const CAREERS_URL = 'http://bit.ly/HIHHJobBoard';
export const CONTENT_URL = process.env.REACT_APP_CONTENT_URL || "";
export const MAIL_TO_URL = 'mailto:support@healthinherhue.com';

const routes = {
  careers_url: CAREERS_URL,
  content_url: CONTENT_URL,
  mail_to_url: MAIL_TO_URL,
};

export default routes;
